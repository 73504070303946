.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
      /* background:-moz-linear-gradient(0deg, rgba(204, 243, 255, 1) 0%, rgba(204, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 51%); 
      background:-webkit-linear-gradient(0deg, rgba(204, 243, 255, 1) 0%, rgba(204, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 51%);
      background:-o-linear-gradient(0deg, rgba(204, 243, 255, 1) 0%, rgba(204, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 51%);  
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CCF3FF', endColorstr='#FFFFFF', GradientType=1 );
      background:-ms-linear-gradient(0deg, rgba(204, 243, 255, 1) 0%, rgba(204, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 51%); */
      background:linear-gradient(0deg, #85f6b4 0%,  #a2f6c5 0%, rgba(255, 255, 255) 51%);
}